import { IPagedResponse } from '@ready/table.core';
import { ILocation } from '../../services/types/ILocation.type';
import executeApiAsync, { ResponseType } from '../../services/utils/executeApiAsync';
import config from '../../config';
import { IPayoutHistoryReportItem, PaginationResponse } from '@ready/dashboardv2api.contracts';
import { ReportsResourceActions, ResourceType } from '@ready/security.core';
import toPaginationResponse from 'services/utils/toPaginationResponse';
import { saveAs } from 'file-saver';
import moment from 'moment';

const BASE_URL = `${config.readyDashboardApiEndpoint}/companies`;

export const getPermittedLocations = async (contextId: string): Promise<IPagedResponse<ILocation>> => {
  const securityParams = `permissions=${ResourceType.reports}.${ReportsResourceActions.viewPaymentDataReport}`;
  const showHiddenLocations = false;
  const url = `${BASE_URL}/${contextId}/permittedlocations?${securityParams}&isHidden=${showHiddenLocations}`;
  return await executeApiAsync<IPagedResponse<ILocation>>(url);
};
export interface IGetPayoutHistoryReportArgs {
  contextId: string;
  query: string;
  page: number;
  sortColumn: string;
  sortDirection: string;
}

export const getPayoutHistoryReport = async ({
  contextId,
  query = '',
  page = 1,
  sortColumn = 'usageCount',
  sortDirection = 'desc',
}: IGetPayoutHistoryReportArgs): Promise<PaginationResponse<IPayoutHistoryReportItem>> => {
  const url =
    `${BASE_URL}/${contextId}/reports/payoutHistory?` +
    decodeURIComponent(query) +
    `&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&limit=50`;

  const response = await executeApiAsync<PaginationResponse<IPayoutHistoryReportItem>>(url);
  return toPaginationResponse({ results: response.items, totalCount: response.total }, page, 50);
};

export const exportPayoutHistoryReport = async (args: {
  contextId: string;
  payoutId: string;
  locationId?: string;
}): Promise<void> => {
  const { contextId, payoutId, locationId = '' } = args;
  const url = `${BASE_URL}/${contextId}/reports/payoutHistory/${payoutId}/export?location=${locationId}`;
  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob }, false);
  saveAs(blob, `payout_history_${contextId}_${payoutId}.csv`);
};

interface ExportMultiPayoutHistoryArgs {
  contextId: string;
  locationIds?: string[];
  selectedPayoutIds?: string[];
}

export const exportMultiPayoutHistoryReport = async ({
  contextId = '',
  locationIds = [],
  selectedPayoutIds = [],
}: ExportMultiPayoutHistoryArgs): Promise<void> => {
  const uniqueLocationIdsArray = Array.from(new Set(locationIds.map((id) => id)));
  const locationIdsString = uniqueLocationIdsArray.join(',');
  const payoutIdsString = selectedPayoutIds.join(',');

  const timestamp = moment().format('YYYY-MM-DD_hh-mm-ss_A');
  const fileName = `payout_history_${timestamp}.csv`;

  const url = `${BASE_URL}/${contextId}/reports/payoutHistory/export?payoutIds=${payoutIdsString}&locationIds=${locationIdsString}`;  
  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob }, false);
  saveAs(blob, fileName);
};


interface ExportPayoutHistoryByCompanyArgs {
  companyId: string;
  dateFrom: string;
  dateTo: string;
  locationId?: string;
}

export const exportAllPayoutHistory = async ({
  companyId,
  dateFrom,
  dateTo,
  locationId
}: ExportPayoutHistoryByCompanyArgs): Promise<void> => {

  const params = new URLSearchParams({
    companyId,
    dateFrom,
    dateTo,
  });

  if (locationId) {
    params.append('locationId', locationId);
  }


  const url = `${BASE_URL}/${companyId}/reports/payoutHistory/exportAll?${params.toString()}`;
  const timestamp = moment().format('YYYY-MM-DD_hh-mm-ss_A');
  const fileName = `payout_history_${timestamp}.csv`;
  const blob = await executeApiAsync<Blob>(url, { responseType: ResponseType.Blob }, false);
  saveAs(blob, fileName);
};
