import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader } from '../../components/TableV2/TableHeader/TableHeader';
import { TableV2 } from '../../components/TableV2/TableV2';
import { BodyRowsClickable, BodySkeleton } from '../../components/TableV2/Body/Body';
import LayoutContent from '../../components/AppLayout/LayoutContent';
import { ToolBar } from './components/ToolBar/ToolBar';
import { usePageTitle, useSearchParams } from '../../hooks';
import { PaginationContainer } from '../../components/PaginationContainer/PaginationContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { getInitialPaymentReportUrlQueries } from '../../utils/urlUtils/initDateFilterUrlQuery';
import {
  fetchPayoutHistoryReportThunk,
  fetchPermittedLocationsThunk,
  exportMultiPayoutHistoryReportThunk,
  exportAllPayoutHistoryThunk,
} from './redux/thunks';
import { selectPayoutHistoryReport } from './redux/selectors';
import { columns, getCells, getHeaderCells, headerColumns, initDateFilter, rowKey } from './settings';
import styles from './PayoutHistoryReportPage.module.scss';
import { startOfMonth } from 'date-fns';
import { updateURLSearchParams } from 'utils/updateURLSearchParams';
import HeaderControls, { ControlOption } from 'components/AppLayout/HeaderControls';
import { useAppSelector } from 'redux/store';
import { IPayoutHistoryReportItem } from '@ready/dashboardv2api.contracts';
import { getSearchParam } from 'utils/urlUtils';

const getSelectedLocationIds = (
  reports: IPayoutHistoryReportItem[],
  selectedCheckBoxes: { [key: string]: boolean }
) => {
  const selectedLocationIds: string[] = [];
  reports.forEach((report) => {
    if (selectedCheckBoxes[report.id]) {
      selectedLocationIds.push(report.locationId);
    }
  });
  return selectedLocationIds;
};

const getSelectedTransferIds = (
  reports: IPayoutHistoryReportItem[],
  selectedCheckBoxes: { [key: string]: boolean }
) => {
  const selectedTransferIds: string[] = [];
  reports.forEach((report) => {
    if (selectedCheckBoxes[report.id]) {
      selectedTransferIds.push(report.transferId);
    }
  });
  return selectedTransferIds;
};

const shouldIgnoreClick = (element: HTMLElement): boolean => {
  const classesToIgnore = [
    'export',
    'checkbox-container',
    'icon-dashboard-icons_checkmark',
    'checkbox',
    'checkbox__box',
  ];

  const isLabelWithCheckboxClass = element.nodeName === 'LABEL' && element.classList.contains('checkbox');
  const isSpanWithCheckboxBoxClass = element.nodeName === 'SPAN' && element.classList.contains('checkbox__box');

  return (
    classesToIgnore.some((className) => element.classList.contains(className)) ||
    isLabelWithCheckboxClass ||
    isSpanWithCheckboxBoxClass
  );
};

export const PayoutHistoryReportPage = () => {
  usePageTitle('Payout History', 'Reports');
  const { query, page, sortColumn, sortDirection } = useSearchParams(true);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { contextId, permittedLocations, loading, items, pagination } = useSelector(selectPayoutHistoryReport);
  const { checkboxes: selectedCheckBoxes } = useAppSelector((state) => state.ui.checkboxStates);
  const locationParamId = getSearchParam(decodeURIComponent(query), 'location') || undefined;

  const dispatch = useDispatch();
  const [isExporting, setIsExporting] = React.useState(false);

  useEffect(() => {
    if (!query) {
      const endDate = new Date();
      const startDate = startOfMonth(endDate);
      const redirectUrl = `${pathname}?query=${getInitialPaymentReportUrlQueries(startDate, endDate)}`;
      push(redirectUrl);
    }
  }, [pathname, push, query, dispatch]);

  useEffect(() => {
    dispatch(fetchPermittedLocationsThunk(contextId));
  }, [contextId, dispatch]);

  useEffect(() => {
    if (query) {
      dispatch(fetchPayoutHistoryReportThunk({ contextId, query, page, sortColumn, sortDirection }));
    }
  }, [contextId, query, page, sortColumn, sortDirection, dispatch]);

  const cells = getCells();
  const rowIds = items.map((item) => item.id.toString());
  const headerCells = getHeaderCells(rowIds);

  const onPayoutClick = (rowdata: any, e: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = e.target as HTMLElement;

    if (shouldIgnoreClick(targetElement)) return;

    const detailsQueryParams = updateURLSearchParams(query, [{ name: 'location', value: rowdata.locationId }]);

    const sanitizedValue = encodeURIComponent(detailsQueryParams.toString());
    push(`/companies/${contextId}/payoutHistory/${rowdata.transferId}?query=${sanitizedValue}`);
  };

  const decodedQuery = decodeURIComponent(query);
  const params = new URLSearchParams(decodedQuery);
  const dateFrom = params.get('startDate') ?? '';
  const dateTo = params.get('endDate') ?? '';

  


  const headerControlOptions = [
    {
      buttonLabel: 'Export Selected Items',
      onClick: async () => {
        setIsExporting(true);
        await dispatch(
          exportMultiPayoutHistoryReportThunk({
            contextId,
            locationIds: getSelectedLocationIds(items, selectedCheckBoxes),
            selectedPayoutIds: getSelectedTransferIds(items, selectedCheckBoxes),
          })
        );
        setIsExporting(false);
      },
      primary: true,
      loading: isExporting,
      disabled: Object.values(selectedCheckBoxes).every((value) => !value),
    } as ControlOption,


    {
      buttonLabel: 'Export All',
      onClick: async () => {
        setIsExporting(true);
        await dispatch(
          exportAllPayoutHistoryThunk({
            companyId: contextId,
            dateFrom,
            dateTo,
            locationId: locationParamId,
          })
        );
        setIsExporting(false);
      },
      loading: isExporting,
      disabled: loading.list,
      primary: true,
    } as ControlOption,
  ];

  return (
    <LayoutContent
      title='Payout History'
      toolbarContent={
        <ToolBar initDateFilter={initDateFilter} locations={permittedLocations.results} initialCountry={'CA'} />
      }
      containerType='within'
      scrollToolbarForMobile
      headerControls={
        <HeaderControls controlOptions={headerControlOptions} additionalClassName={styles.headerControls} />
      }
    >
      <div className={styles.table}>
        <PaginationContainer pagination={pagination}>
          <TableV2 loading={loading.list}>
            <>
              <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
              {loading.list ? (
                <BodySkeleton columns={columns} rowsNumber={50} />
              ) : (
                <BodyRowsClickable
                  cells={cells}
                  hiddenColumns={{}}
                  rowKey={rowKey}
                  data={items}
                  columns={columns}
                  onClick={onPayoutClick}
                />
              )}
            </>
          </TableV2>
        </PaginationContainer>
      </div>
    </LayoutContent>
  );
};
